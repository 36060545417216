import { api } from '@/config/axios';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { ExpextedResponse, LoginData, LoginMessage, UserLogin, UserBulkRegister, JwtDecode } from '@/entities/Auth';
import { defineStore } from 'pinia';
import jwtDecode from 'jwt-decode';
import Storage from "@/misc/Storage";


interface State {
    message: LoginMessage
    data: LoginData | null;
}

export const useAuthStore = defineStore('AuthStore', {
    state: (): State => ({
        message: {
            en: '',
            id: ''
        },
        data: null,
    }),
    actions: {
        setRegister(payload: UserLogin): Promise<AxiosResponse<ExpextedResponse>> {
            return new Promise((resolve, reject) => {
                api.post('/v1/auth/signup', payload, {}).then((res: AxiosResponse<ExpextedResponse>) => {
                    resolve(res);
                }).catch((err: AxiosError) => {
                    if (err.code !== '500') {
                        this.message.id = err.response?.data.message.id
                        reject(this.message.id)
                    }
                    reject(err)
                });
            });
        },
        setBulkRegister(payload: UserBulkRegister): Promise<AxiosResponse<ExpextedResponse>> {
            const formData = new FormData();
            formData.append("archive_file", payload.archive_file)
            
            return new Promise((resolve, reject) => {
                api.post('/v1/auth/signup/bulk', formData, {}).then((res: AxiosResponse<ExpextedResponse>) => {
                    resolve(res);
                }).catch((err: AxiosError) => {
                    if (err.code !== '500') {
                        this.message.id = err.response?.data.message.id
                        reject(this.message.id)
                    }
                    reject(err)
                });
            });
        },
        getBulkRegisterTemplate(): Promise<AxiosResponse<string>> {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_REGISTER_TEMPLATE_URL, {}).then((res: AxiosResponse<string>) => {
                    resolve(res);
                }).catch((err: AxiosError) => {
                    if (err.code !== '500') {
                        reject("Failed download template file")
                    }
                    reject(err)
                });
            });
        },
        setLogin(payload: UserLogin): Promise<AxiosResponse<ExpextedResponse>> {
            return new Promise((resolve, reject) => {
                api.post('/v1/auth/login', payload, {
                    noAuthorization: true,
                })
                    .then((res: AxiosResponse<ExpextedResponse>) => {
                        this.data = res.data.data;
                        const decode: JwtDecode = jwtDecode(res.data.data.token);
                        Storage.setCredential({ token: res.data.data.token, expired: decode.exp, isAdmin: decode.is_admin })
                        resolve(res);
                    })
                    .catch((err: AxiosError) => {
                        if (err.code !== '500') {
                            this.message.id = err.response?.data.message.id
                            reject(this.message.id)
                        }
                        reject(err)
                    });
            });
        }
    },
    getters: {
        getToken(): string {
            return ""
        }
    },
});