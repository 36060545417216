
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import { UserLogin } from "@/entities/Auth";
import { useAuthStore } from "@/stores/auth/AuthStore";
import Alert from "@/misc/Alert";

export default defineComponent({
  setup() {
    const authStore = useAuthStore();
    const router = useRouter();

    const formLogin = reactive<UserLogin>({
      username: "",
      password: "",
    });

    const login = (): void => {
      authStore
        .setLogin(formLogin)
        .then(() => {
          router.push("/");
        })
        .catch((err) => {
          Alert.Toast({
            type: "error",
            message: err,
            width: 400,
            position: "top",
          });
        });
    };

    return {
      login,
      formLogin,
    };
  },
});
